import { usePrefersReducedMotion } from '@hooks';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import profileImg from '../../images/pic.jpg';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin-left: 0;
  margin-top: 150px;
  margin-bottom: 50px;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    flex-wrap: wrap;
    margin-top: 150px;
  }

  @media (max-width: 740px) {
    margin-left: 0;
    flex-wrap: wrap;
    margin-top: 150px;
  }
`;

const StyledProfilePic = styled.img`
  max-width: 45%;
  border-radius: 35%;
  margin-left: auto;
  margin-right: -260px;

  @media (max-width: 1485px) {
    margin-right: -100px;
  }

  @media (max-width: 1200px) {
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    margin-top: 10%;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    margin-top: 50px;
    margin-bottom: -20px;
  }

  @media (max-width: 420px) {
    max-width: 90%;
  }
`;

const Hero = () => {
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Maxim Nosov.</h2>;
  const three = <h3 className="big-heading">I create immersive web experiences</h3>;
  const four = (
    <>
      <p>
        I'm Creative front-end developer with 5 years of experience in enterprise companies and
        startups. I have a strong passion for creating visually stunning and user-friendly web
        interfaces.
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="https://www.linkedin.com/in/mn622"
      target="_blank"
      rel="noreferrer">
      Check out my LinkedIn!
    </a>
  );

  const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      <StyledContent>
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      </StyledContent>
      <StyledProfilePic src={profileImg} alt="Profile" />
    </StyledHeroSection>
  );
};

export default Hero;
